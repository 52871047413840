/**药品元数据匹配查看 */
import request from '@/utils/request'
// 获取表格数据
export function tableDataApi(data) {
  return request({
    url: '/dm/drug/upload/detail/list',
    method: 'get',
    params: data
  })
}
// 根据上传记录ID获取该批次的类型集合
export function uploadTypeApi(data) {
  return request({
    url: '/dm/drug/upload/type/' + data.recordId,
    method: 'get'
  })
}
