<!--药品元数据匹配查看-->
<template>
  <el-container class="match-view-container layout-container">
    <el-header style="height: auto;background-color: #fff;">
      <el-row class="headerClass" style="padding: 20px 0 0;" :gutter="10">
        <!-- <el-col :span="4" class="common-title">药品元数据匹配查看</el-col> -->
        <el-col :span="4" style="border-left: 5px solid #0073E9;padding-left: 10px;
        border-radius: 2px;">药品元数据匹配查看</el-col>
        <el-col :span="20">
          <el-form :model="queryParams" ref="queryForm" :inline="true" v-show="showSearch" style="float: right">
            <el-form-item label="" prop="searchValue">
              <!-- <el-autocomplete
                v-model="queryParams.searchValue"
                :fetch-suggestions="querySearchAsync"
                placeholder="输入编号/药品名称/商品名/生产厂家/批号"
                clearable
                size="small"
                value-key="name"
                style="width: 300px;height: 36px!important;line-height: 36px;"/> -->
              <el-input
                v-model="queryParams.searchValue"
                clearable
                size="small"
                value-key="name"
                placeholder="输入关键字"/>
            </el-form-item>
            <el-form-item label="停用标志:" prop="deleteFlag">
              <el-select
                v-model="queryParams.deleteFlag"
                placeholder=""
                clearable
                size="small"
                class="statusClass"
                style="width: 100px">
                <el-option
                  v-for="dict in stopFlagOptions"
                  :key="dict.value"
                  :label="dict.label"
                  :value="dict.value"/>
              </el-select>
            </el-form-item>
            <el-form-item label="状态:" prop="status">
              <el-select
                v-model="queryParams.status"
                placeholder=""
                clearable
                size="small"
                class="statusClass"
                style="width: 100px">
                <el-option
                  v-for="dict in statusOptions"
                  :key="dict.value"
                  :label="dict.label"
                  :value="dict.value"/>
              </el-select>
            </el-form-item>
            <el-form-item label="类别:" prop="type">
              <el-select
                v-model="queryParams.type"
                placeholder="类别"
                clearable
                size="small"
                class="statusClass"
                style="width: 100px">
                <el-option
                  v-for="(item, index) in typeOptions"
                  :key="index"
                  :label="item"
                  :value="item"/>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" icon="el-icon-search" size="small" @click="searchHandle">查询</el-button>
              <el-button size="small" @click="backHandle">返回</el-button>
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
    </el-header>
    <el-main ref="tableContainer" style="padding: 0 20px;background-color: #fff;">
      <el-table
        border
        stripe
        ref="refundTable"
        v-loading="tableLoading"
        :data="drugList"
        :header-cell-style="{'text-align':'center'}"
        :cell-style="{'text-align':'center'}"
        :cell-class-name="cellClassName"
        height="100%"
        @row-contextmenu="rowContextmenu">
        <el-table-column
          label="状态"
          prop="isMapStr"
          width="90"
          fixed/>
        <el-table-column
          label="停用标志"
          prop="deleteFlag"
          :formatter="stopUsing"
          width="90"
          fixed/>
        <template v-for="item in COLUMN_LIST">
          <el-table-column
            v-if="item.show"
            :prop="item.prop"
            :fixed="item.fixed"
            :width="item.width"
            :min-width="item.minWidth"
            :label="item.label"
            show-overflow-tooltip/>
        </template>
        <template slot="empty" v-if="isResult === false">
          <img src="@/assets/images/no-booking.svg" alt="">
          <p>暂无数据</p>
        </template>
        <template slot="empty" v-else>
          <img src="@/assets/images/no-left-data.svg" alt="">
          <p>未查询到结果，请修改查询条件重试！</p>
        </template>
      </el-table>
      <!-- <el-table
        :data="tableData"
        :span-method="objectSpanMethod"
        :row-merge-number="2"
        row-merge-hover-key="id"
        border
        style="width: 100%; margin-top: 20px">
        <el-table-column
          prop="id"
          label="ID"
          width="180"
          fixed>
        </el-table-column>
        <el-table-column
          prop="name"
          label="姓名">
        </el-table-column>
        <el-table-column
          prop="amount1"
          label="数值 1（元）">
        </el-table-column>
        <el-table-column
          prop="amount2"
          label="数值 2（元）">
        </el-table-column>
        <el-table-column
          prop="amount3"
          label="数值 3（元）">
        </el-table-column>
      </el-table> -->
    </el-main>
    <!-- <table-context-menu
      v-if="tableContextMenuVisible"
      @foo="foo" ref="contextbutton"
      :action="tableAction"
      @handleTableAction="handleTableAction"/> -->
    <el-footer>
      <div class="current-info-wrap">
        <span class="item-label">上传时间：</span>
        <span class="item-text">{{ currentRecordObj.uploadTime }}</span>
        <span class="item-label">状态：</span>
        <span class="item-text" :class="circleClassShow(currentRecordObj.status)">{{ currentRecordObj.status | statusText }}</span>
        <span class="item-label">上传方式：</span>
        <span class="item-text item-way">{{ currentRecordObj.uploadType | uploadText}}</span>
      </div>
      <pagination
        v-show="total>0"
        :total="total"
        :page.sync="queryParams.pageNum"
        :limit.sync="queryParams.pageSize"
        @pagination="getTableData"/>
    </el-footer>
  </el-container>
</template>

<script>
import { list, updateStatus, selectDrugGroupNameList } from '@/api/dkm/drugGroup'
import tableConst from '@/const/tableConst'
import { getConcept } from '@/utils/concept'
import { UCgetConfig } from '@/utils/columnSettings'
import { GLOBAL_CONFIGURATION_CONFIGKEY } from '@/utils/constants'
import {
  getRecordDetailApi
} from '@/api/dm/drugMetadataUpload/drugMetadataUpload'
import {
  MATCH_STATUS_OPTIONS,
  COLUMN_LIST,
  STOP_FLAG_OPTIONS
} from '@/utils/dm/drugMetadataUpload/data'
import {
  tableDataApi,
  uploadTypeApi
} from '@/api/dm/drugMetadataUpload/matchView'
export default {
  name: 'drugMetadataUploadMatchView',
  components: {},
  data() {
    return {
      tableData: [{
        id: '12987122',
        name: '王小虎',
        amount1: '234',
        amount2: '3.2',
        amount3: 10
      }, {
        id: '12987123',
        name: '王小虎',
        amount1: '165',
        amount2: '4.43',
        amount3: 12
      }, {
        id: '12987124',
        name: '王小虎',
        amount1: '324',
        amount2: '1.9',
        amount3: 9
      }, {
        id: '12987125',
        name: '王小虎',
        amount1: '621',
        amount2: '2.2',
        amount3: 17
      }, {
        id: '12987126',
        name: '王小虎',
        amount1: '621',
        amount2: '2.2',
        amount3: 17
      }],
      rowSpanArr: [], // 用来存放合并行的数组
      recordId: '',
      COLUMN_LIST: this.$deepClone(COLUMN_LIST),
      currentRecordObj: {
        uploadTime: '',
        status: '',
        uploadType: ''
      },
      drugGroupList: [], // 物质组列表
      tableConst,
      tableLoading: true,
      // 遮罩层
      loading: false,
      // 显示搜索条件
      showSearch: true,
      // 总条数
      total: 0,
      // 药品维护表格数据
      drugList: [],
      // 表格高度
      tableHeight: window.innerHeight - 100 - 100,
      // 状态数据字典
      statusOptions: this.$deepClone(MATCH_STATUS_OPTIONS),
      stopFlagOptions: this.$deepClone(STOP_FLAG_OPTIONS),
      typeOptions: [],
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        recordId: '',
        searchValue: null,
        status: '',
        type: '',
        deleteFlag: '0', // 停用标志 0:在用 1:停用(默认在用)
      },
      resourceDataOption: [],
      /*当前操作表格行数据*/
      editTableRow: [],
      /* 来源引用表格数据*/
      resourceTableForm: {
        list: []
      },
      sltConfigKey: '', // 药物管理-药物列表-表格配置
      sltConfigClassify: '',
      // searchValue: '',
      columnOptions: [],
      refundForm: {
        refundTableData: []
      },
      isResult: false,
      /*表格右键菜单显示隐藏*/
      tableContextMenuVisible:false,
      tableAction:[{name:"修改"},{name:"删除",style:'color: #FF3333;'}]
    }
  },
  filters: {
    mapStatusText(val) {
      if (Number(val) === 1) {
        return '已映射'
      } else if (Number(val) === 2) {
        return '未映射'
      } else if (Number(val) === 3) {
        return '非药品'
      }
    },
    statusText(val) {
      // 1:已上传;2:待匹配;3:待处理;4:匹配完成
      if (Number(val) === 1) {
        return '已上传'
      } else if (Number(val) === 3) {
        return '待匹配'
      } else if (Number(val) === 2) {
        return '待处理'
      } else if (Number(val) === 4) {
        return '匹配完成'
      }
    },
    uploadText(val) {
      if (Number(val) === 1) {
        return 'WEB'
      } else if (Number(val) === 2) {
        return 'API'
      }
    }
  },
  computed: {
    circleClassShow() {
      return function (val) {
        if (Number(val) === 1) {
        return 'uploaded'
      } else if (Number(val) === 2) {
        return 'match'
      } else if (Number(val) === 3) {
        return 'handle'
      } else if (Number(val) === 4) {
        return 'finished'
      }
      }
    },
    fixedTableHeight() {
      return ''
    },
    userDept() {
      return this.$store.getters.dept
    },
    showShangHai() {
      const { provinceCode } = this.userDept
      if (Number(provinceCode) === 310000) {
        return true
      } else {
        return false
      }
    }
  },
  async created() {
    console.log(this.$store.getters.dept)
  },
  async activated() {
    this.recordId = this.$route.params.id
    await this.fetchOneRecordData()
    await this.fetchTypeData()
    await this.getTableData()
    console.log(this.rowSpanArr)
  },
  mounted() {
    // 获取高度值 （内容高+padding+边框）
    let height = this.$refs.tableContainer.offsetHeight
    const that = this
    window.onresize = () => {
      return (() => {
        //let heightStyle = that.$refs.tableContainer.offsetHeight
        //that.tableHeight = heightStyle
        //// console.log("调整",heightStyle)
        //// console.log('window.innerHeight - x', window.innerHeight)
        that.tableHeight = window.innerHeight - 300 + 'px'
      })()
    }
  },
  methods: {
    objectSpanMethod({ row, column, rowIndex, columnIndex }) {
      if (columnIndex === 0) {
        // 第一列，两行合并成一行
        if (rowIndex % 2 === 0) {
          this.rowSpanArr.push(rowIndex)
          return {
            rowspan: 2,
            colspan: 1
          };
        } else {
          return {
            rowspan: 0,
            colspan: 0
          };
        }
      }
    },
    cellClassName({row, column, rowIndex, columnIndex}) {
      if (columnIndex === 0) {
        if (Number(row.mapStatus) === 1 || row.isMapStr === '未映射') {
          return 'no-map'
        } else if (Number(row.mapStatus) === 2 || row.isMapStr === '已映射') {
          return 'maped'
        } else if (Number(row.mapStatus) === 3 || row.isMapStr === '非药品') {
          return 'non-drug'
        }
      }
    },
    // 获取上传类型
    async fetchTypeData() {
      let res = await uploadTypeApi({ recordId: this.recordId })
      if (res.code === 200) {
        this.typeOptions = res.data || []
      }
      // console.log(this.typeOptions)
    },
    // 获取某一条药品元数据的信息
    async fetchOneRecordData() {
      const obj = {
        recordId: this.recordId
      }
      let res = await getRecordDetailApi(obj)
      if (res && res.code === 200) {
        this.currentRecordObj = res.data
      }
    },
    // 点击查询按钮
    async searchHandle() {
      this.isResult = true
      this.tableLoading = true
      this.queryParams.pageNum = 1
      await this.getTableData()
    },
    // 获取表格数据调接口
    async getTableData() {
      this.queryParams.recordId = this.recordId
      let res = await tableDataApi(this.queryParams)
      if (res && res.code === 200) {
        this.drugList = res.data.rows
        this.total = res.data.total
        this.tableLoading = false
      }
    },
    // 远程搜索获取药物组名称
    async remoteMethodSL (query) {
      if (query !== '') {
        this.loading = true
        let queryData = {}
        queryData.queryStr = query
        let res = await selectDrugGroupNameList(queryData)
        if (res && res.code === 200) {
          this.loading = false
          this.drugGroupList = res.data
        }
      } else {
        this.drugGroupList = []
      }
    },
    /** 查询药品维护列表 */
    selectSubstanceList() {
      this.loading = true
      list(this.queryParams).then(response => {
        this.drugList = response.data
        this.drugList.forEach((item) => {
          // 药物成分
          item.drugComponent = []
          item.dkbDrugGroupMappingList.forEach((j) => {
            let code = ''
            code = 'id：' + j.drugId + '，' + j.drugName + '；'
            item.drugComponent.push(code).toString()
          })
          item.drugComponent = item.drugComponent.toString().replace(/,/g, '')
          // 药物名称
          item.dkbDrugAliasList.forEach((j) => {
            if (j.isPrimary === "1") {
              item.name = j.alias
            }
          })
        })
        this.total = response.total
        this.loading = false
      })
    },
    /*返回按钮*/
    backHandle() {
      // 关闭当前tab页
      this.$store.dispatch('tagsView/delView', this.$route)
      // 返回上一步路由
      this.$router.push({ path:'/drugMetadataUpload/list'})
    },
    /** 新增按钮操作 */
    handleAdd() {
      this.$router.push('/drugGroup/edit/')
    },
    /** 修改按钮操作 */
    handleUpdate(row) {
      const durgId = row.id
      this.$router.push('/drugGroup/edit/' + durgId)
    },
    /*处理来源引用表格删除*/
    handleResourceDelete(index, row) {
      this.resourceTableForm.list.splice(index, 1)
    },
    /*重置表单*/
    resetForm(formName) {
      let form = this.$refs[formName]
      if (form) {
        form.resetFields()
      }
    },
   /* 药物组名称检索*/
    querySearchAsync(queryString, cb) {
      if (queryString && queryString.trim() !== '') {
        this.loading = true
        setTimeout(() => {
          this.loading = false
          let queryData = {}
          queryData.queryStr = queryString.trim()
          selectDrugGroupNameList(queryData).then(response => {
            cb(response.data);
          })
        }, 200)
      } else {
        cb([]);
      }
    },
    /*鼠标右键点击事件*/
    rowContextmenu (row, column, event) {
      this.tableContextMenuVisible = false
      this.tableContextMenuVisible = true
      // 阻止右键默认行为
      event.preventDefault()
      this.$nextTick(() => {
        this.$refs.contextbutton && this.$refs.contextbutton.init(row,column,event)
      })
    },
    /* 取消鼠标监听事件 菜单栏*/
    foo() {
      this.tableContextMenuVisible = false
      document.removeEventListener('click', this.foo)
    },
    /* 处理表格右键菜单点击*/
    handleTableAction (actionName,row, column) {
      if(actionName==='修改'){
        this.handleUpdate(row)
      }else if(actionName==='删除'){
        this.handleDelete(row)
      }
    },
    stopUsing(row, column, cellValue, index) {
      if (cellValue === null || cellValue === '0') {
        return '在用'
      } else if (cellValue === '1') {
        return '停用'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.match-view-container {
  ::v-deep.el-table > td.maped > .cell > span {
    display: inline-block;
    background: #D8E2FF;
    border-radius: 3px;
    color: #2B60F8;
    padding: 5px 6px;
  }
  ::v-deep.el-table td.no-map > .cell > span {
    display: inline-block;
    background: #DDDDDD;
    border-radius: 3px;
    color: #666;
    padding: 5px 6px;
  }
  ::v-deep.el-table td.non-drug > .cell > span {
    display: inline-block;
    background: #DDDDDD;
    border-radius: 3px;
    color: orange;
    padding: 5px 6px;
  }
  ::v-deep.el-table {
    height: 100%;
    .el-table__fixed {
      /* height:auto !important;  */
      height: calc(100% - 12px) !important;
      bottom: 16px;
    }
  }
  .el-table {
    ::v-deep td.non-drug {
      .cell {
        background: #D8E2FF;
        border-radius: 3px;
        color: orange;
        padding: 5px 6px !important;
      }
    }
    ::v-deep td.maped {
      .cell {
        background: #D8E2FF;
        border-radius: 3px;
        color: #2B60F8;
        padding: 5px 6px !important;
      }
    }
    ::v-deep td.no-map {
      .cell {
        background: #DDDDDD;
        border-radius: 3px;
        color: #666;
        padding: 5px 6px !important;
      }
    }
  }
  .el-table {
    ::v-deep.el-table__empty-block {
      position: relatvie;
      .el-table__empty-text {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
  .el-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 50px !important;
    background-color:#fff;
    ::v-deep .current-info-wrap {
      font-size: 14px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #666666;
      .item-label {
      }
      .item-text {
        color: #333;
        margin-right: 15px;
        &.uploaded {
          color: #51C1E7;
        }
        &.match {
          color: #FF9F4D;
        }
        &.handle {
          color: #FF5759;
        }
        &.finished {
          color: #2DCD50;
        }
      }
    }
  }
}
.el-table .cell .el-form-item {
  margin-bottom: 0px;
}

/*查看来源引用图标*/
.el-icon-question{
  color: #E99B00;
  font-size: 16px;
  margin-right: 6px;
}
.leftIconName{
  display:block;
  float:left;
  text-align:right;
  min-width:40px;
}
.rightContentName{
  display:block;
  float:left;
  word-break:break-all;
  display:-webkit-box; /**对象作为伸缩盒子模型展示**/
  -webkit-box-orient:vertical; /**设置或检索伸缩盒子对象的子元素的排列方式**/
  -webkit-line-clamp:2; /**显示的行数**/
  overflow:hidden;
  text-align:left;
}
.leftIcon{
  display:block;
  float:left;
  // width:50px;
}
.rightContent{
  display:block;
  float:left;
  width:650px;
  word-break:break-all;
  display:-webkit-box; /**对象作为伸缩盒子模型展示**/
  -webkit-box-orient:vertical; /**设置或检索伸缩盒子对象的子元素的排列方式**/
  -webkit-line-clamp:2; /**显示的行数**/
  overflow:hidden;
  text-align:left;
}
.iconStyle{
  display: inline-block;
  // background: #ff0;
  min-width: 30px;
  text-align: right;
}
.delClass{
  text-decoration: line-through #ff0000;
}
</style>
